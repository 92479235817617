var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { height: "calc(100% - 40px)", overflow: "auto" },
    },
    [_c("div", { domProps: { innerHTML: _vm._s(_vm.text) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }