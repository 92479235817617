var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "big-data-container" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "content-input-box", style: `height: 100%;` }, [
        _c(
          "div",
          { style: `height: 100%;` },
          [
            _c(
              "el-tabs",
              {
                attrs: { "tab-position": "left" },
                on: { "tab-click": _vm.onTabClick },
                model: {
                  value: _vm.tabName,
                  callback: function ($$v) {
                    _vm.tabName = $$v
                  },
                  expression: "tabName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { name: "about", label: "关于监控平台" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "m-l-20 m-t-10",
                        staticStyle: { height: "100%" },
                      },
                      [_vm.tabName === "about" ? _c("about") : _vm._e()],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-tab-pane",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { name: "download", label: "文档下载" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "m-l-20 m-t-10",
                        staticStyle: { height: "100%" },
                      },
                      [_vm.tabName === "download" ? _c("download") : _vm._e()],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-tab-pane",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { name: "versions", label: "改版履历" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "m-l-20 m-t-10",
                        staticStyle: { height: "100%" },
                      },
                      [_vm.tabName === "versions" ? _c("versions") : _vm._e()],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }