<template>
  <div class="big-data-container">
    <div class="content">
      <div class="content-input-box" :style="`height: 100%;`">
        <div :style="`height: 100%;`">
          <el-tabs tab-position="left" v-model="tabName" @tab-click="onTabClick">
              <el-tab-pane name="about" label="关于监控平台" style="height: 100%">
                <div class="m-l-20 m-t-10" style="height: 100%">
                  <about v-if="tabName === 'about'" />
                </div>
              </el-tab-pane>
              <el-tab-pane name="download" label="文档下载" style="height: 100%">
                <div class="m-l-20 m-t-10" style="height: 100%">
                  <download v-if="tabName === 'download'" />
                </div>
              </el-tab-pane>
              <el-tab-pane name="versions" label="改版履历" style="height: 100%">
                <div class="m-l-20 m-t-10" style="height: 100%">
                  <versions v-if="tabName === 'versions'" />
                </div>
              </el-tab-pane>
            </el-tabs>
        </div>
      </div>
      </div>
    </div>
</template>
<script>
import about from "./about";
import versions from "./versions";
import download from "./download";

export default {
  components: {
    about,
    versions,
    download
  },
  data () {
    return {
      tabName: "about"
    }
  }
}
</script>