<template>
  <div v-loading="loading" style="height: calc(100% - 40px); overflow:auto;">
      <div v-html="text"></div>
  </div>
</template>
<script>
import myMarkedjsPlus from "modell-markedjs-plus";

export default {
  data () {
    return {
      loading: false,
      text: ""
    }
  },
  async created () {
    try {
      this.loading = true;
      const file = await $utils.readFile(`${process.env.VUE_APP_BASE_URL}/wxapp/upload/document/versions.md`);
      this.text = modellMarkedPlus.parse(file);
    } catch (err) {
      err.stack = err.stack.replace(/(\r\n|\r|\n)/g, "<br />");
      this.text = err;
    } finally {
      this.loading = false;
    }
  }
}
</script>