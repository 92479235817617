<template>
  <div style="height: 100%;">
    <div>
      <el-radio-group v-model="radio" size="medium" @input="onSelectRadio">
        <el-radio-button label="系统概述"></el-radio-button>
        <el-radio-button label="注册登录"></el-radio-button>
        <el-radio-button label="功能说明"></el-radio-button>
        <el-radio-button label="多级权限"></el-radio-button>
      </el-radio-group>
    </div>
    <div style="height: 100%;">
      <div ref="text" class="document-box" v-loading="loading" @scroll="getScrollTop">
        <div v-html="text"></div>
      </div>
      <div class="to-top-btn">
        <el-button icon="el-icon-caret-top" type="primary" circle @click="goBack"></el-button>
      </div>
    </div>
  </div>
</template>
<script>
import "modell-markedjs-plus/dist/modell-markedjs-plus.css"
import myMarkedjsPlus from "modell-markedjs-plus";

export default {
  data () {
    return {
      loading: false,
      radio: "系统概述",
      text: "",
      scrollTop: 0, //默认距离顶部的距离
      scrollTrigger: false, //默认初始值
    }
  },
  created () {
    this.onSelectRadio();
  },
  methods: {
    async onSelectRadio (event) {
      try {
        this.loading = true;
        const file = await $utils.readFile(`${process.env.VUE_APP_BASE_URL}/wxapp/upload/document/about/${this.radio}.md`);

        const plus = modellMarkedPlus.create();
        plus.setImageDefaultAlign("center");
        plus.setFileDefaultUrl(`${process.env.VUE_APP_BASE_URL}/wxapp/upload/document/images/`);
        this.text = plus.parse(file);
      } catch (err) {
        console.log(err);
        err.stack = err.stack.replace(/(\r\n|\r|\n)/g, "<br />");
        this.text = err;
      } finally {
        this.loading = false;
        this.goBack();
      }
    },
    // 返回顶部事件
    goBack() {
      const that = this; // 防止this指向问题
      // 防止频繁点击，故返回顶部后设置scrollTrigger为初始值
      if (that.scrollTrigger) {
        return;
      }
      // 获取当前距离顶部的距离
      let scrollTop = this.scrollTop;
      let steep = scrollTop / 2000;
      let timer = setInterval(() => {
        that.scrollTrigger = true;
        // 滚动的速度逐渐变快，第一次走2000/1，然后减去已走的距离，下一次用剩下的距离再减去步进值，步进值也是不断变化，这样速度会越来越快
        scrollTop -= steep;
        // 步进值不改变的话会匀速缓慢上滑，不断增加步进值上滑的距离增加，视觉效果速度变快
        steep += 20;
        if (scrollTop <= 0) {
          clearInterval(timer);
          that.scrollTrigger = false;
        }
        that.$refs.text.scrollTop = scrollTop;
      }, 30);
    },
    // 监听滚动条
    getScrollTop(e) {
      let that = this; // 防止this指向问题
      // 设备一屏的高度
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let scrollTop = e.srcElement.scrollTop;
      this.scrollTop = scrollTop;
    },
  }
}
</script>
<style lang="scss" scoped>
.document-box {
  height:calc(100% - 60px);
  width: calc(100% - 60px);
  margin-top:10px;
  overflow:auto;
}

.document-box, .to-top-btn {
  display: inline-block;
  vertical-align: bottom;
}

.to-top-btn {
  margin-left: 20px;
}
</style>