var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { height: "100%" } }, [
    _c(
      "div",
      [
        _c(
          "el-radio-group",
          {
            attrs: { size: "medium" },
            on: { input: _vm.onSelectRadio },
            model: {
              value: _vm.radio,
              callback: function ($$v) {
                _vm.radio = $$v
              },
              expression: "radio",
            },
          },
          [
            _c("el-radio-button", { attrs: { label: "系统概述" } }),
            _c("el-radio-button", { attrs: { label: "注册登录" } }),
            _c("el-radio-button", { attrs: { label: "功能说明" } }),
            _c("el-radio-button", { attrs: { label: "多级权限" } }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticStyle: { height: "100%" } }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "text",
          staticClass: "document-box",
          on: { scroll: _vm.getScrollTop },
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.text) } })]
      ),
      _c(
        "div",
        { staticClass: "to-top-btn" },
        [
          _c("el-button", {
            attrs: { icon: "el-icon-caret-top", type: "primary", circle: "" },
            on: { click: _vm.goBack },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }